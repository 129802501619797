// Entry point for the build script in your package.json

import Rails from "@rails/ujs"
Rails.start()

import "@hotwired/turbo-rails"

import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()

import "jquery"
import "bootstrap"

// TODO: are_you_sure_form.coffee.erb do przepisania od nowa
import "./vendor"
import "chartkick/chart.js"

import "./controllers"

import { Controller } from '@hotwired/stimulus'
import LocalSettings from '../lib/local_settings'

export default class extends Controller {
  static targets = ['addField', 'addSubfield', 'toggle']
  connect() {
    this.element[this.identifier] = this
    this.toggle(LocalSettings.getValue('hotkeysEnabled'))
  }

  keyPress(e) {
    if (!LocalSettings.getValue('hotkeysEnabled') || window.countOpenModals > 0) return;

    if (e.target.matches('input')) {
      if (e.key === 'Escape') {
        this.data.set('lastFocusedInputId', e.target.id)
        e.target.blur()
      } else if (e.key === 's' && e.altKey === true && e.ctrlKey === true) {
        this._openSpecialCharsModal()
      } else {
        return;
      }
    } else if (e.key === '=') {
      this._addSubfield();
    } else if (e.key === '+') {
      this._addField();
    }
  }

  toggle(force) {
    const bool = (typeof force === 'boolean') ? force : !LocalSettings.getValue('hotkeysEnabled')
    if (this.hasToggleTarget) this.toggleTarget.checked = bool
    LocalSettings.setValue('hotkeysEnabled', bool)
  }

  _addField() {
    this._openSelect2(this.addFieldTarget);
  }

  _addSubfield() {
    const fieldSelector = `[data-${this.identifier}-target='field']`;
    const activeField = document.activeElement.closest(fieldSelector)
    if (activeField) {
      const addSubfieldTarget = this.addSubfieldTargets.find((addSubfieldTarget) => {
        return addSubfieldTarget.closest(fieldSelector) === activeField;
      })
      this._openSelect2(addSubfieldTarget);
    } else if (this.data.has('lastFocusedInputId')) {
      const lastFocusedInputId = this.data.get('lastFocusedInputId');
      const addSubfieldTarget = this.addSubfieldTargets.find((addSubfieldTarget) => {
        return addSubfieldTarget.closest('fieldset').querySelector(`[id='${lastFocusedInputId}']`)
      })
      this._openSelect2(addSubfieldTarget);
    } else {
      this._openSelect2(this.addSubfieldTargets.pop());
    }
  }

  _openSelect2(node) {
    $(node).select2('open');
  }

  _openSpecialCharsModal() {
    const el = this.element.querySelector('#special-chars-modal')
    if (el) {
      el['modal--basic']?.open()
    }
  }


}

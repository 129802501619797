import WithSelectController from "./with_select_controller";

export default class extends WithSelectController {
  static targets = [
    "itemsGroup", // items group container
  ]

  addItem(value) {
    const template = this.templateTargets.find((template) => template.dataset.itemValue === value);
    const content = this.replaceItemId(template);
    const group = this.itemsGroupTargets.find(e => { return e.dataset.itemValue == value })
    group.insertAdjacentHTML('beforeend', content)
    const item = Array.from(group.childNodes).filter((child) => child.nodeType == Node.ELEMENT_NODE).pop()
    this.afterAddItem(item)
    return item
  }
}

// TODO: update modal after upgrade to bootstrap 5

import { Controller } from '@hotwired/stimulus'
import Modal from "../../lib/bootstrap/modal"

export default class extends Controller {
  connect () {
    this.modal = new Modal(this.element, { focus: true })
    this.modal.show()
  }

  disconnect () {
    this.modal.dispose()
  }

  hide () {
    this.modal.hide()
  }

  submitEnd (e) {
    if (e.detail.success) this.hide()
  }
}

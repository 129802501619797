// TODO: usunięcie
// przed usunięciem zmiana tabel: bib_schema_dirs → bundled_bib_schemas, index_value_groups → bundled_index_values, index_value_pages
import Select2Controller from "./select2_controller";

// example:
// <input type="hidden" id="index_id" value="1">
// <select
//  data-controller="select2-dynamic"
//  data-select2-dynamic-url-value="/pl/api/indexes"
//  data-select2-dynamic-text-key-value="name"
//  data-select2-dynamic-query-name-value="filter[name_start]"
//  data-select2-dynamic-filters-value" => '[{"elSelector": "#index_id", "paramName":"filter[id_eq]"}
//  data-select2-id="index_value_group_index_id">
//    <option value="0">Dyscyplina</option>
//  </select>
export default class extends Select2Controller {
  static values = {
    url: String,
    textKey: String, // object key containing option text
    queryName: String, // query parameter name to search
    filters: Array // input value filters: [{ "elSelector": "[selector of input]", "paramName": "[query parameter name]" }]
  }

  get select2_options() {
    return {
      ajax: {
        delay: 250,
        url: this.urlValue,
        processResults: (data) => this.process_results(data),
        data: (params) => this.process_params(params)
      }
    }
  }

  init() {
    $(this.element).select2(this.select2_options)
  }

  process_results(data) {
    return {
      results: data.data.map(item => {
        return {
          id: item.id,
          text: item.attributes[this.textKeyValue]
        }
      }),
      // define if has more pages
      pagination: { more: !!data.links.next }
    }
  }

  process_params(params) {
    const query = {
      page: { number: params.page || 1 },
      [this.queryNameValue]: params.term // set search value
    }
    // set filters
    this.filtersValue.forEach((filter) => {
      const els = document.querySelectorAll(filter.elSelector)
      if (els.length > 1) {
        els.forEach(el => {
          if ("value" in el) {
            query[filter.paramName] ||= []
            query[filter.paramName].push(el.value)
          }
        })
      } else if (els[0] && "value" in els[0]) {
        query[filter.paramName] = els[0].value
      }
    })
    return query
  }
}

Pagy.addInputEventListeners = function (input, handler) {
  // select the content on click: easier for typing a number
  input.addEventListener('click', function () {
    return this.select();
  });
  // go when the input looses focus
  // input.addEventListener 'focusout', handler
  // … and when pressing enter inside the input
  return input.addEventListener('keyup', (function (e) {
    if (e.which === 13) {
      return handler();
    }
  }).bind(this));
};

document.addEventListener("turbo:load", function (event) {
  return Pagy.init();
});